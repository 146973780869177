import {consola} from "consola";
import {useIZNewsUtils} from "./useIZNewsUtils";
import {TypeNewsCategoryDto, TypeNewsDto} from "./news.type";
import {useParseData} from "../../../utils/parse.utils";

export const useIZNewsListing = () => {

  const globalSettings = inject("globalSettings");
  const {getItems} = useDirectusItems();
  const {generateNewsDto, generateNewsCategoryDto, logger} = useIZNewsUtils(globalSettings);
  const {calTotalPage} = useParseData();
  const TOP_NEWS: number = 5;
  const LIMIT = 3;

  const getListing = async (langCode: string, options: {
    slug: any,
    query?: {
      limit?: string | number,
      page?: number
    }
  }) => {
    return getItems({
      collection: "news_category",
      params: {
        fields: ["*", "translations.*", 'translations.seo_details.*',],
        filter: {
          _and: [
            {
              status: {
                _eq: "published"
              }
            },
            {
              translations: {
                languages_code: {
                  _eq: langCode
                }
              }
            },
            {
              translations: {
                slug: {
                  _eq: options.slug
                }
              }
            }
          ]
        },
        sort: ["sort", "date_created"],
        page: 1,
        limit: -1
      }
    }).then(
      (res: any) => {
        return generateNewsCategoryDto(res[0], langCode) as TypeNewsCategoryDto;
      })
      .then(async (category: any) => {
        const page = options.query?.page || 1;
        const limit = options.query?.limit || LIMIT;
        if (category.display_mode != "1") {

          let on_top: Array<any> = [];
          let query: any = {
            collection: "news",
            params: {
              fields: [
                "id",
                "status",
                "date_created",
                "sort",
                "platform",
                "on_top",
                "hero",
                "tags.tags_id.id", "tags.tags_id.status",
                "tags.tags_id.translations.languages_code",
                "tags.tags_id.translations.title",
                "category.id",
                "category.status",
                "category.show",
                "category.display_mode",
                "category.show_subscription",
                "category.translations.id",
                "category.translations.languages_code",
                "category.translations.title",
                "category.translations.slug",
                "category.translations.intro", ,
                "translations.languages_code",
                "translations.title",
                "translations.slug",
                "translations.intro",
                "translations.thumbnail.id",
                "translations.thumbnail.title",
                "translations.thumbnail.description",],
              filter: {
                _and: [
                  {
                    status: {
                      _eq: "published"
                    }
                  },
                  {
                    category: {
                      _eq: category.id
                    }
                  }
                ]
              },
              sort: ["-hero", "-on_top", "-date_created"],
              meta: ["filter_count"],
              limit: +limit + TOP_NEWS,
            }
          }

          if (page === 1) {
            return getItems({
              ...query
            })
              .then(({data, meta}: any) => {
                const news = data.map((ite: any) => {
                  return generateNewsDto(ite, langCode) as TypeNewsDto;
                });
                on_top = news.slice(0, TOP_NEWS);
                const latest_news = news.slice(TOP_NEWS);
                return {
                  on_top,
                  latest_news,
                  total_page: calTotalPage(+meta.filter_count - TOP_NEWS, options.query?.limit || LIMIT) > 0 ? calTotalPage(+meta.filter_count - TOP_NEWS, options.query?.limit || LIMIT) : 1,
                  total_result: (meta.filter_count - TOP_NEWS) >= 0 ? (meta.filter_count - TOP_NEWS) : 0,
                  current_page: options.query?.page || 1,
                  meta,
                  lang_switch: category.lang_switch || [],
                  model: category
                }
              }).catch((e: any) => {
                logger.error("Error: getListingNews: ", e);
                return [];
              })

          } else if (page === 2) {

            query.params.limit = +limit;
            query.params.offset = +limit + TOP_NEWS;
            return getItems({
              ...query
            }).then(({data, meta}: any) => {

              const news = data.map((ite: any) => {
                return generateNewsDto(ite, langCode) as TypeNewsDto;
              });

              return {
                latest_news: news,
                total_page: calTotalPage(+meta.filter_count - TOP_NEWS, options.query?.limit || LIMIT) > 0 ? calTotalPage(+meta.filter_count - TOP_NEWS, options.query?.limit || LIMIT) : 1,
                total_result: (meta.filter_count - TOP_NEWS) >= 0 ? (meta.filter_count - TOP_NEWS) : 0,
                current_page: options.query?.page || 1,
                meta,
                lang_switch: category.lang_switch || [],
                model: category
              }
            }).catch((e: any) => {
              logger.error("Error: getListing!");
              logger.debug(e);
              return [];
            })
          } else {
            query.params.limit = +limit;
            query.params.offset = ((page - 1) * (+limit)) + TOP_NEWS;

            return getItems({
              ...query
            }).then(({data, meta}: any) => {

              const news = data.map((ite: any) => {
                return generateNewsDto(ite, langCode) as TypeNewsDto;
              });

              return {
                latest_news: news,
                total_page: calTotalPage(+meta.filter_count - TOP_NEWS, options.query?.limit || LIMIT),
                total_result: meta.filter_count - TOP_NEWS,
                current_page: options.query?.page || 1,
                meta,
                lang_switch: category.lang_switch || [],
                model: category
              }
            }).catch((e: any) => {
              logger.error("Error: getListing: ");
              logger.debug(e);
              return [];
            })
          }
        } else {
          let query: any = {
            collection: "news",
            params: {
              fields: [
                "id",
                "status",
                "date_created",
                "sort",
                "platform",
                "on_top",
                "hero",
                "tags.tags_id.id", "tags.tags_id.status",
                "tags.tags_id.translations.languages_code",
                "tags.tags_id.translations.title",
                "category.id",
                "category.status",
                "category.show",
                "category.display_mode",
                "category.show_subscription",
                "category.translations.id",
                "category.translations.languages_code",
                "category.translations.title",
                "category.translations.slug",
                "category.translations.intro",
                "translations.languages_code",
                "translations.title",
                "translations.slug",
                "translations.intro",
                "translations.thumbnail.id",
                "translations.thumbnail.title",
                "translations.thumbnail.description",],
              filter: {
                _and: [
                  {
                    status: {
                      _eq: "published"
                    }
                  },
                  {
                    category: {
                      _eq: category.id
                    }
                  }
                ]
              },
              sort: ["-on_top", "-date_created"],
              meta: ["filter_count"],
              limit: +limit,
            }
          }

          return getItems({
            ...query
          })
            .then(({data, meta}: any) => {

              const news = data.map((ite: any) => {
                return generateNewsDto(ite, langCode) as TypeNewsDto;
              });

              return {
                latest_news: news,
                total_page: calTotalPage(+meta.filter_count, options.query?.limit || LIMIT),
                total_result: meta.filter_count,
                current_page: options.query?.page || 1,
                meta,
                lang_switch: category.lang_switch || [],
                model: category
              }
            }).catch((e: any) => {
              logger.error("Error: getListing!");
              logger.debug(e);
              return [];
            })
        }
      })
  }

  return {
    getListing,
    logger
  }
}
