<template>
  <section class="container mt-9 mb-21 overflow-hidden" v-if="dataRender && dataRender.length > 0">
    <!-- <div class="grid grid-cols-3 mb-22">
        <p class="uppercase text-bw-09/50 text-base leading-6 font-medium col-span-1">gợi ý các chủ đề</p>
        <ul class="col-span-2 flex gap-x-2.5 gap-y-4.5 flex-wrap">
            <li v-for="(index, item) in 15">
                <a
                    href=""
                    class="bg-final-05 rounded-full py-2 px-3.5 uppercase text-primary text-13px leading-6 font-medium"
                >
                    sự kiện
                </a>
            </li>
        </ul>
    </div> -->
    <div
        data-aos="fade-up"
        data-aos-once="true"
        class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-8 gap-y-20 mb-20"
    >
      <GlobalItemGridNew
          v-for="news in dataRender"
          :key="news?.id"
          @click="trackingNews(news)"
          :dataItem="news"></GlobalItemGridNew>
    </div>
    <div data-aos="fade-up" data-aos-once="true" class="w-full flex justify-center">
      <GlobalPagination class="" :totalPage="res?.total_page" :currentPage="res?.current_page"></GlobalPagination>
    </div>
  </section>
</template>

<script lang="ts">
export default {
  name: 'sectionListNews'
}
</script>

<script setup lang="ts">
import {ref, computed} from 'vue'
import {
  NEWS_EVENT_ID,
  useEventTracking
} from "../../../composables/ackee/event";

const {getListing} = useIZNewsListing()

const route = useRoute()
// const _slugCategory = route.params.id.split('-').pop()
const _slugCategory = route.params.slug

const pageSearch = computed(() => route.query?.page || 1)
const res = ref()
const dataRender = ref()
const getData = async () => {
  res.value = await getListing(route.params.langCode, {
    slug: _slugCategory,
    query: {page: +pageSearch.value, limit: 12}
  }).then((response) => response)
  dataRender.value = res.value?.latest_news
}

const trackingNews = (dataItem: any) => {
  useEventTracking(NEWS_EVENT_ID, `${dataItem?.category?.title} - ${dataItem?.title}`)
}
// console.log(dataRender)
getData()

watch(
    () => pageSearch.value,
    () => {
      getData()
    }
)
</script>

<style></style>
