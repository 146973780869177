<template>
  <banner v-if="loadingBreadcrumb" :breadcrumb="breadcrumb"></banner>
  <navigation isCategory :data="dataCategory" :idActive="_slugCategory"></navigation>
  <section-ontop-category></section-ontop-category>
  <section-list-news></section-list-news>
  <section-download></section-download>
  <section-sign-up></section-sign-up>
</template>

<script lang="ts">
export default {
  name: 'newCategory'
}
</script>

<script setup lang="ts">
import Banner from './components/Banner.vue'
import Navigation from './components/Navigation.vue'
import SectionOntopCategory from './components/SectionOntopCategory.vue'
import SectionListNews from './components/SectionListNews.vue'
import SectionDownload from './components/SectionDownload.vue'
import SectionSignUp from './components/SectionSignUp.vue'

import {useGetLangCurrent} from '../../stores/useLanguageMenu'

const {getFeaturedCategories} = useIZNewsWidgets()
const {getListing} = useIZNewsListing()
const {t} = useI18n()

const resCategoryCEO = ref({})
const dataCategory = ref(null)
const loadingBreadcrumb = ref(false)

const route = useRoute()
// const _idCategory = route.params.id.split('-').pop()
const _slugCategory = route.params.slug

const res = await getListing(route.params.langCode, {slug: _slugCategory, query: {page: 1, limit: 5}}).then(
    (response) => response
)

const breadcrumb = ref<Object[]>([
  {
    title: t('NEWS'),
    url: t('URL_ROUTER_NEWS')
  }
])

await getFeaturedCategories(route.params.langCode, {})
    .then((response) => {
      dataCategory.value = response
      response.map(async (ite: any) => {
        if (ite.slug == _slugCategory) {
          resCategoryCEO.value = ite
          breadcrumb.value.push(ite)
          loadingBreadcrumb.value = true
        }
      })
    })
    .catch((error) => {
      console.error(error)
    })

useDirectusCollectionSeo('news_category', {
  ...resCategoryCEO.value,
  seo_detail: {
    ...resCategoryCEO.value?.seo_detail
  }
})

const store = useGetLangCurrent()
onMounted(() => {
  store.lang_switch = res?.lang_switch
})
</script>

<style></style>
